@import './src/scss/variables';
@import './src/scss/app';

.widget__iq {
	&-container {
    display: flex;
    align-items: center;
    justify-content: center;
		user-select: none;
	}

	&-progress {
	  height: $max-height;
    display: flex;
    align-items: center;
    justify-content: center;

		&_list {
	    display: flex;
	    flex-wrap: wrap;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
	    gap: 1rem;

	    li {
				display: flex;
		    align-items: center;
		    gap: .5rem;

		    a {
          display: flex;
          align-items: center;
          gap: .5rem;
		    }
	    }
		}

		&_list-row {
			flex-direction: row;
		}
	}

	&-test {
	  display: flex;
    justify-content: space-evenly;
    padding: 1rem;
	}
}

.question {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	&__control {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: .5rem;
	}
}

.answer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 20%;

	&__skip {
		display: flex;
		justify-content: center;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: .5rem;
		}
	}

	&__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
	}
}

.navigation {
  margin-top: 2rem;

  &__item {
    width: 50px;
    height: 40px;
    padding: 5px;
    display: inline-block;
    border: 1px solid gray;

    &.selected {
      border-color: blue
    }

    &.current {
      border-width: 2px;
    }
  }
}


.answers-wrapper {
  .answers {
    display: flex;
    max-width: 175px;
    flex-wrap: wrap;
    height: 100%;
    min-height: 285px;

    &-selected {
      img {
        background-color: grey;
      }
    }

    div {
      padding: 5px;
      border-radius: 5px;

      &:hover {
        background-color: lightgrey;
      }
    }

    img {
      max-width: 75px;
    }
  }
}

.info-wrapper {
  .info-item {
    &-current-result {
      border-color: red;
      display: unset;
    }
  }
}

.answer__correct {
    border: 3px solid #ff003d;
    padding: 2px;
}